import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { FileManager } from '@cubone/react-file-manager';
import '@cubone/react-file-manager/dist/style.css';
import './test.css';

const Test = () => {
  const title = 'VCOM-Merchant';
  const [files, setFiles] = useState([
    {
      name: 'Content',
      isDirectory: true,
      path: '/Content',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Theme Collections',
      isDirectory: true,
      path: '/Content/Theme Collections',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Theme Collections/Display Stands',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Pic.png',
      isDirectory: false,
      path: '/Content/Theme Collections/Display Stands/Pic.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Other Stuff',
      isDirectory: true,
      path: '/Content/Theme Collections/Other Stuff',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Pic.png',
      isDirectory: false,
      path: '/Content/Theme Collections/Other Stuff/Pic.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Interior',
      isDirectory: true,
      path: '/Content/Interior',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Interior/Display Stands',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Pic.png',
      isDirectory: false,
      path: '/Content/Interior/Display Stands/Pic.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Other Stuff',
      isDirectory: true,
      path: '/Content/Interior/Other Stuff',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Pic.png',
      isDirectory: false,
      path: '/Content/Interior/Other Stuff/Pic.png',
      updatedAt: '2024-09-08T16:45:00Z',
      size: 2048,
    },
    {
      name: 'Marketing Content',
      isDirectory: true,
      path: '/Content/Marketing Content',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Marketing Content/Display Stands',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Other Stuff',
      isDirectory: true,
      path: '/Content/Marketing Content/Other Stuff',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Props',
      isDirectory: true,
      path: '/Content/Props',
      updatedAt: '2024-09-09T11:00:00Z',
    },
    {
      name: 'Display Stands',
      isDirectory: true,
      path: '/Content/Props/Display Stands',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Other Stuff',
      isDirectory: true,
      path: '/Content/Props/Other Stuff',
      updatedAt: '2024-09-09T10:30:00Z',
    },
    {
      name: 'Products',
      isDirectory: true,
      path: '/Products',
      updatedAt: '2024-09-11T16:45:00Z',
    },
    {
      name: 'Apps',
      isDirectory: true,
      path: '/Apps',
      updatedAt: '2024-09-11T16:45:00Z',
    },
    {
      name: 'Themes',
      isDirectory: true,
      path: '/Themes',
      updatedAt: '2024-09-11T16:45:00Z',
    },
  ]);

  const handleCreateFolder = (newName, parentFolder) => {
    const newFolder = {
      name: newName,
      isDirectory: true,
      path: `${parentFolder.path}/${newName}`,
      updatedAt: new Date(),
    };
    setFiles([...files, newFolder]);
  };

  const handleRename = (updatedFile, newName) => {
    const foundIndex = files.findIndex((x) => x.name === updatedFile.name);
    files[foundIndex] = {
      name: newName,
      isDirectory: updatedFile.isDirectory,
      path: `${updatedFile.path.substring(0, updatedFile.path.lastIndexOf('/'))}/${newName}`,
      updatedAt: new Date(),
    };
    setFiles(files);
  };

  const handleRefresh = () => {
    console.log('Refreshing the files');
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="container-fluid">
        <div className="px-4 py-4">
          <FileManager
            files={files}
            onCreateFolder={handleCreateFolder}
            onRename={handleRename}
            onRefresh={handleRefresh}
            enableFilePreview={false}
            filePreviewPath={process.env.REACT_APP_URL}
          />
        </div>
      </main>
    </>
  );
};

export default Test;
