import { useState } from 'react';
import {
  Button, Collapse, Accordion, Row, Col, Form, InputGroup, ListGroup, Image,
} from 'react-bootstrap';
import Surface from '../../../assets/images/properties/Surface.png';
import SurfaceBottom from '../../../assets/images/properties/SurfaceBottom.png';
import SurfaceTop from '../../../assets/images/properties/SurfaceTop.png';
import SurfaceLeft from '../../../assets/images/properties/SurfaceLeft.png';
import SurfaceRight from '../../../assets/images/properties/SurfaceRight.png';
import SurfaceFront from '../../../assets/images/properties/SurfaceFront.png';
import SurfaceBack from '../../../assets/images/properties/SurfaceBack.png';

const PropertyList = () => {
  const [open, setOpen] = useState(true);

  const handleOpenProperty = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="properties">
        <Button
          onClick={handleOpenProperty}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          Properties
        </Button>
      </div>
      <div className="sidebarCard scrollbarCard">
        <Collapse in={open}>
          <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Measures (mm)</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>W</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>D</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                    <div className="linkIcon">
                      <Button variant="secondary"><i className="bi bi-link-45deg"></i></Button>
                    </div>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>H</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Position</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>X</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Y</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Z</Form.Label>
                      <Form.Control type="number" min={1} max={99999} />
                      <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Rotation</Accordion.Header>
              <Accordion.Body>
                <Row className="m-0">
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>X</Form.Label>
                      <Form.Control type="number" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Y</Form.Label>
                      <Form.Control type="number" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                  <Col xs={4} className="px-1">
                    <InputGroup className="align-items-center">
                      <Form.Label>Z</Form.Label>
                      <Form.Control type="number" />
                      <Button variant="secondary"><i className="bi bi-arrow-clockwise"></i></Button>
                    </InputGroup>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Glue By</Accordion.Header>
              <Accordion.Body>
                <ListGroup horizontal className="align-items-center">
                  <ListGroup.Item className="bg-transparent border-0 p-0">Surface</ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={Surface} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceBottom} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceTop} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceLeft} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceRight} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceFront} /></Button>
                  </ListGroup.Item>
                  <ListGroup.Item className="bg-transparent border-0 p-0">
                    <Button><Image src={SurfaceBack} /></Button>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Materials</Accordion.Header>
              <Accordion.Body>
                <InputGroup className="align-items-center pe-4">
                  <Form.Label>Primary texture</Form.Label>
                  <Form.Control type="text" name="primaryTexture" />
                  <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>Commerce</Accordion.Header>
              <Accordion.Body>
                <InputGroup className="align-items-center pe-4">
                  <Form.Label>Product theme</Form.Label>
                  <Form.Control type="text" name="productTheme" />
                  <Button variant="secondary"><i className="bi bi-pencil-fill"></i></Button>
                </InputGroup>
                <InputGroup className="align-items-center mt-2 pe-4">
                  <Form.Label className="pe-4 me-3">Buyable</Form.Label>
                  <Form.Check
                    type="switch"
                  />
                </InputGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Collapse>
      </div>
    </>
  );
};

export default PropertyList;
