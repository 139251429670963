import { useState } from 'react';
import {
  Button, ButtonGroup, Collapse,
} from 'react-bootstrap';
import Folder from './Objects/Folder';
import File from './Objects/File';

const folderStructureData = [
  {
    id: 1,
    name: 'Store Front',
    isFolder: true,
    items: [],
  },
  {
    id: 2,
    name: 'Entrance floor',
    isFolder: true,
    items: [
      {
        id: 21,
        name: 'Interior',
        isFolder: true,
        items: [
          {
            id: 121,
            name: 'Chair',
            isFolder: false,
          },
          {
            id: 122,
            name: 'Chair 2',
            isFolder: false,
          },
          {
            id: 123,
            name: 'Floor lamp',
            isFolder: false,
          },
          {
            id: 124,
            name: 'Product stand large',
            isFolder: false,
          },
        ],
      },
      {
        id: 22,
        name: 'Products',
        isFolder: true,
        items: [
          {
            id: 221,
            name: 'Alba Modern Chair',
            isFolder: false,
          },
          {
            id: 222,
            name: 'Chair2',
            isFolder: false,
          },
          {
            id: 223,
            name: 'Floor lamp',
            isFolder: false,
          },
        ],
      },
      {
        id: 23,
        name: 'Apps',
        isFolder: true,
        items: [],
      },
    ],
  },
  {
    id: 3,
    name: 'Avtars',
    isFolder: true,
    items: [],
  },
  {
    id: 4,
    name: 'Tablet',
    isFolder: true,
    items: [],
  },
];

const ObjectList = () => {
  const [open, setOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState({});

  const handleOpenObject = () => {
    setOpen(!open);
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  return (
    <>
      <div className="properties">
        <Button
          onClick={handleOpenObject}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          Objects
        </Button>
      </div>
      <div className="plusIcon text-end">
        <ButtonGroup className="px-3 py-2">
          <Button><i className="bi bi-plus-circle-dotted"></i></Button>
          <Button><i className="bi bi-trash"></i></Button>
        </ButtonGroup>
      </div>
      <div className="productObject scrollbarCard">
        <Collapse in={open}>
          <div id="example-collapse-text" style={{ overflow: 'auto', minHeight: '100px' }}>
            <div className="sidebarCard">
              {folderStructureData.map((item) => (item.isFolder ? <Folder key={`asset_${item.id}`} item={item} selectedItem={selectedItem} handleSelectedItem={handleSelectedItem} /> : <File key={item.id} item={item} />))}
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default ObjectList;
