import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
} from 'react-bootstrap';
import {
  Stage, Layer,
} from 'react-konva';

import './solutionedit.css';
import ProductList from '../../../components/virtual/solutionedit/ProductList';
import MyAssetLibrary from '../../../components/virtual/solutionedit/MyAssetLibrary';
import ObjectList from '../../../components/virtual/solutionedit/ObjectList';
import PropertyList from '../../../components/virtual/solutionedit/PropertyList';
import { solutionDetail } from '../../../services/SolutionService';
import AppList from '../../../components/virtual/solutionedit/AppList';
import ContentList from '../../../components/virtual/solutionedit/ContentList';
import URLImage from '../../../components/virtual/solutionedit/URLImage';

const folderStructureData = [
  {
    id: 1,
    name: 'Content',
    isFolder: true,
    depth: 1,
    link: '/',
    children: [
      {
        id: 11,
        name: 'Theme Collections',
        isFolder: true,
        depth: 2,
        link: '/themecollection',
        children: [
          {
            id: 111,
            name: 'Display Stands',
            isFolder: true,
            depth: 3,
            link: '/themecollection/displaystands',
            children: [],
          },
          {
            id: 112,
            name: 'Other stuff',
            isFolder: true,
            depth: 3,
            link: '/themecollection/otherstuff',
            children: [],
          },
        ],
      },
      {
        id: 12,
        name: 'Interior',
        isFolder: true,
        depth: 2,
        link: '/interior',
        children: [
          {
            id: 121,
            name: 'Display Stands',
            isFolder: true,
            depth: 3,
            link: '/interior/displaystands',
            children: [],
          },
          {
            id: 122,
            name: 'Other stuff',
            isFolder: true,
            depth: 3,
            link: '/interior/otherstuff',
            children: [],
          },
        ],
      },
      {
        id: 13,
        name: 'Marketing Content',
        isFolder: true,
        depth: 2,
        link: '/marketingcontent',
        children: [
          {
            id: 131,
            name: 'Display Stands',
            isFolder: true,
            depth: 3,
            link: '/marketingcontent/displaystands',
            children: [],
          },
          {
            id: 132,
            name: 'Other stuff',
            isFolder: true,
            depth: 3,
            link: '/marketingcontent/otherstuff',
            children: [],
          },
        ],
      },
      {
        id: 14,
        name: 'Props',
        isFolder: true,
        depth: 2,
        link: '/props',
        children: [
          {
            id: 141,
            name: 'Display Stands',
            isFolder: true,
            depth: 3,
            link: '/props/displaystands',
            children: [],
          },
          {
            id: 142,
            name: 'Other stuff',
            isFolder: true,
            depth: 3,
            link: '/props/otherstuff',
            children: [],
          },
        ],
      },
    ],
  },
];

const SolutionEdit = () => {
  // eslint-disable-next-line no-unused-vars
  const [solution, setSolution] = useState({});
  const { solutionId } = useParams();
  const [showContent, setShowContent] = useState(true);
  const [contentSelected, setContentSelected] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const [optionSelected, setOptionSelected] = useState('');
  const parentDivRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const dragUrl = useRef();
  const stageRef = useRef();
  const [objects, setObjects] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      setSelectedId(null);
    }
  };

  const handleShow = (option, data) => {
    setContentSelected(data);
    setOptionSelected(option);
    if (option === 1) {
      setShowContent(true);
      setShowProduct(false);
      setShowApp(false);
    } else if (option === 2) {
      setShowContent(false);
      setShowProduct(true);
      setShowApp(false);
      // setContentSelected({});
    } else if (option === 3) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(true);
      // setContentSelected({});
    }
  };

  // const handleDragStart = (e) => {
  //   const id = e.target.id();
  //   setObjects(
  //     // eslint-disable-next-line
  //     objects.map((object) => {
  //       return {
  //         ...object,
  //         isDragging: object.id === id,
  //       };
  //     }),
  //   );
  // };

  // const handleDragEnd = (e) => {
  //   setStars(
  //     // eslint-disable-next-line
  //     objects.map((object) => {
  //       return {
  //         ...object,
  //         isDragging: false,
  //       };
  //     }),
  //   );
  // };

  const getSolution = async () => {
    try {
      const response = await solutionDetail(solutionId);
      if (response.success) {
        setSolution(response.data);
      } else {
        setSolution({});
      }
    } catch (error) {
      setSolution({});
    }
  };

  useEffect(() => {
    (async () => {
      await getSolution();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionId]);

  useEffect(() => {
    if (parentDivRef.current?.offsetHeight && parentDivRef.current?.offsetWidth) {
      setDimensions({
        width: parentDivRef.current.offsetWidth,
        height: parentDivRef.current.offsetHeight,
      });
    }
  }, [parentDivRef.current?.offsetWidth, parentDivRef.current?.offsetHeight]);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="virtualAssets">
        <Row className="m-0">
          <div className="p-0 virtualCanvasArea">
            <div className="asstesCount">
              <p className="mb-0 text-end text-white fs-12">FPS: 127ms</p>
            </div>
            <div className="canvasFrame"
              onDrop={(e) => {
                e.preventDefault();
                // register event position
                stageRef.current.setPointersPositions(e);
                // add image
                setObjects(
                  objects.concat([
                    {
                      ...stageRef.current.getPointerPosition(),
                      src: dragUrl.current,
                      height: 100,
                      width: 100,
                    },
                  ]),
                );
              }}
              onDragOver={(e) => e.preventDefault()}
              ref={parentDivRef}
            >
              <Stage
                width={dimensions.width}
                height={dimensions.height}
                ref={stageRef}
                onMouseDown={checkDeselect}
                onTouchStart={checkDeselect}
              >
                <Layer>
                  {objects.map((object, i) => (
                    <URLImage
                      key={i}
                      imgProps={object}
                      isSelected={object.id === selectedId}
                      onSelect={() => {
                        setSelectedId(object.id);
                      }}
                      onChange={(newAttrs) => {
                        const objs = objects.slice();
                        objs[i] = newAttrs;
                        setObjects(objs);
                      }}
                    />
                  ))}
                </Layer>
              </Stage>
            </div>
            <div className="myAssets">
              <div className="searchProduct">
                <Row>
                  <div className="pe-0 leftAssets">
                    <MyAssetLibrary
                      optionSelected={optionSelected}
                      handleShow={handleShow}
                      contentSelected={contentSelected}
                      folderStructureData={folderStructureData}
                    />
                  </div>
                  <div className="ps-0 rightAssets">
                    {showContent
                      && (<ContentList
                          dragUrl={dragUrl}
                          contentSelected={contentSelected}
                          folderStructureData={folderStructureData}
                        />)}
                    {showProduct && <ProductList dragUrl={dragUrl} />}
                    {showApp && <AppList />}
                  </div>
                </Row>
              </div>
            </div>
          </div>
          <div className="p-0 sidebarCanvas">
            <div className="topSideBar">
              <PropertyList />
            </div>
            <div className="bottomSideBar">
              <ObjectList />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default SolutionEdit;
