import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  Image, Nav, Tab,
} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';

import './dashboard.css';
import { getStoreSession, setStoreSession, storeDetail } from '../../services/StoreService';
import { setChargeSession } from '../../services/BillingService';
import { productList } from '../../services/ProductService';
import StoreIcon from '../../assets/images/merchant-card-icon/store.svg';
import SubscriptionIcon from '../../assets/images/merchant-card-icon/subscription.svg';
import ProductsIcon from '../../assets/images/merchant-card-icon/products.svg';
import ImportProductOne from '../../components/merchant/home/ImportProductOne';
import ImportProductTwo from '../../components/merchant/home/ImportProductTwo';
import StoreProductList from '../../components/merchant/product/StoreProductList';
import ConnectStoreOne from '../../components/merchant/home/ConnectStoreOne';
import ConnectStoreTwo from '../../components/merchant/home/ConnectStoreTwo';
import PublishVRStore from '../../components/merchant/home/PublishVRStore';

const Dashboard = () => {
  const storeSession = getStoreSession();
  const [store, setStore] = useState({});
  const [totalSyncedProduct, setTotalSyncedProduct] = useState(0);
  const [showImportProduct, setShowImportProduct] = useState(true);

  const getStoreDetail = async () => {
    if (storeSession) {
      try {
        const response = await storeDetail(storeSession.id);
        if (response.success && response.data) {
          const storeData = response.data;
          setStore(storeData);
          setStoreSession(storeData);
          if (storeData?.subscription_id) {
            setChargeSession(storeData?.subscription_id);
          }
        }
      } catch (error) {
        setStore({});
      }
    }
  };

  const getProductCount = async () => {
    if (store) {
      try {
        const response = await productList(store.id);
        if (response.success) {
          const productCount = response.data?.length;
          setTotalSyncedProduct(productCount);
        } else {
          setTotalSyncedProduct(0);
        }
      } catch (error) {
        setTotalSyncedProduct(0);
      }
    }
  };

  const getVRStoreStatus = () => {
    let storeStatus = 'Not set';
    let storeClass = 'border-l-red';
    if (store && store.vr_store_status === 1) {
      storeStatus = 'Preview';
      storeClass = 'border-l-yellow';
    } else if (store && store.vr_store_status === 2) {
      storeStatus = 'Published';
      storeClass = 'border-l-green';
    }
    return { storeStatus, storeClass };
  };

  const getSubscriptionStatus = () => {
    let storeSubscription = '-';
    let storeClass = 'border-l-green';
    if (store && store.Subscription && store.Subscription?.subscription_reason === 1) {
      const subscriptionAt = moment(store.Subscription?.subscribe_at).add(14, 'd').format('YYYY/MM/DD');
      storeSubscription = `Free trial (Expire ${subscriptionAt})`;
      storeClass = 'border-l-green';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 2) {
      storeSubscription = 'Free trial ended';
      storeClass = 'border-l-red';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 3) {
      storeSubscription = store.Subscription?.Plan?.name;
      storeClass = 'border-l-green';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 4) {
      storeSubscription = 'Missing payment';
      storeClass = 'border-l-yellow';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 5) {
      storeSubscription = 'Store deactivated due to missing payment';
      storeClass = 'border-l-red';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 6) {
      storeSubscription = 'Store deactivated by subscriber';
      storeClass = 'border-l-red';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 8) {
      storeSubscription = 'Store deactivated due to inactivity';
      storeClass = 'border-l-red';
    } else if (store && store.Subscription && store.Subscription?.subscription_reason === 9) {
      storeSubscription = 'Store archived due to inactivity';
      storeClass = 'border-l-red';
    }
    return { storeSubscription, storeClass };
  };

  const handleImportProduct = () => {
    setShowImportProduct(!showImportProduct);
  };

  useEffect(() => {
    (async () => {
      getStoreDetail();
      getProductCount();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      getProductCount();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="dashboard-page">
        <div className="px-3 m_0 mx_3 mx-5 pt-5">
          <div className="row">
            <div className="col-md-5 full-width">
              <h3 className="fw-normal">Merchant Admin Portal</h3>
              <p className="fs-6">Manage VR-store, products, 3D content and much more.</p>
            </div>
            <div className="col-md-7 full-width">
              <div className="row">
                <div className="col-md-4">
                  <div className={`merchantCard ${getVRStoreStatus().storeClass}`}>
                    <div className="infoIcon">
                      <Image src={StoreIcon} alt="StoreIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="text17 mb-0">VR-store</h3>
                      <p className="mb-0 text12 text-muted pt-1">
                        {getVRStoreStatus().storeStatus}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className={`merchantCard ${getSubscriptionStatus().storeClass}`}>
                    <div className="infoIcon">
                      <Image src={SubscriptionIcon} alt="SubscriptionIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="text17 mb-0">Subscription</h3>
                      <p className="mb-0 text12 text-muted pt-1">
                        {getSubscriptionStatus().storeSubscription}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="merchantCard border-l-blue">
                    <div className="infoIcon">
                      <Image src={ProductsIcon} alt="ProductsIcon" />
                    </div>
                    <div className="infoText">
                      <h3 className="text17 mb-0">Synced products</h3>
                      <p className="mb-0 text12 text-muted pt-1">{totalSyncedProduct} items</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card mt-4">
            <div className="setupGuide">
              <h3 className="text20 fw-normal mb-0">Setup guide</h3>
            </div>
            <div className="setupHolder">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="d-flex align-items-start get-ready-menu">
                  <div className="guide-nav-tabs pe-0">
                    <Nav variant="pills" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="first" className={(!store?.is_removed && !store.is_default && store.is_accept_term) ? 'selected' : ''}>
                          {!store?.is_removed && !store.is_default && store.is_accept_term ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                          </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 1000 1000">
                            <path fill="#5f6367" d="M391.003,110H166.92c-32.773,0-59.427,26.665-59.427,59.427v224.082c0,32.788,26.653,59.43,59.427,59.43
                              h224.083c32.763,0,59.416-26.642,59.416-59.43V169.427C450.419,136.665,423.766,110,391.003,110"></path>
                            <path fill="#5f6367" d="M391.003,547.591H166.92c-32.773,0-59.427,26.655-59.427,59.417V831.09
                              c0,32.784,26.653,59.427,59.427,59.427h224.083c32.763,0,59.416-26.643,59.416-59.427V607.008
                              C450.419,574.246,423.766,547.591,391.003,547.591"></path>
                            <path fill="#5f6367" d="M828.583,547.591H604.479c-32.743,0-59.404,26.651-59.404,59.414V831.09
                              c0,32.784,26.661,59.427,59.404,59.427h224.104c32.762,0,59.417-26.643,59.417-59.427V607.005
                              C888,574.242,861.345,547.591,828.583,547.591"></path>
                            <path fill="#5f6367" d="M697.906,244.708v-95.79c0-8.412,3.092-15.594,9.282-21.534c6.184-5.94,13.737-8.911,22.647-8.911
                              c8.412,0,15.594,2.97,21.534,8.911s8.911,13.123,8.911,21.534v95.79h95.789c8.661,0,16.15,3.034,22.463,9.096
                              c6.312,6.068,9.467,13.43,9.467,22.091c0,8.667-3.155,16.029-9.467,22.091c-6.313,6.068-13.802,9.097-22.463,9.097h-95.047v95.79
                              c0,8.911-3.156,16.464-9.468,22.648c-6.312,6.19-13.801,9.282-22.462,9.282c-8.667,0-16.029-3.092-22.092-9.282
                              c-6.067-6.184-9.096-13.737-9.096-22.648v-95.79h-95.79c-8.417,0-15.721-3.028-21.905-9.097
                              c-6.189-6.062-9.282-13.424-9.282-22.091c0-8.412,3.093-15.715,9.282-21.905c6.185-6.184,13.488-9.282,21.905-9.282H697.906z"></path>
                          </svg>)}
                          Connect to online store
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        {(store?.is_default || !store.is_accept_term || (store.Subscription
                        && (store.Subscription?.subscription_reason === 5 || store.Subscription?.subscription_reason === 6
                          || store.Subscription?.subscription_reason === 8 || store.Subscription?.subscription_reason === 9))) ? (
                          <Nav.Link className={store?.vr_store_status === 2 ? 'selected' : ''}>
                            {store?.vr_store_status === 2 ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                            </svg>) : (<svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                              <path d="M817.222,390.516C804.357,203.57,666.992,55.95,500,55.95c-142.84,0-265.43,105.907-305.07,260.421
                              C82.125,361.077,5.035,483.159,5.035,621.092c0,178.073,126.87,322.959,282.83,322.959h459.63
                              c136.447,0,247.47-126.821,247.47-282.583C994.965,534.323,921.799,425.243,817.222,390.516 M651.809,690.032l-70.721-80.748
                              c-10.112-11.578-13.144-28.914-7.692-44.04c5.451-15.009,18.394-24.871,32.659-24.871h30.366
                              C620.603,470.912,565.752,419.251,500,419.251c-19.717,0-38.799,4.51-56.632,13.468c-17.985,8.873-38.749-0.405-46.568-20.797
                              c-7.847-20.448,0.305-44.24,18.188-53.171c26.852-13.408,55.485-20.217,85.012-20.217c104.883,0,192.011,87.469,208.951,201.839
                              h38.544c14.291,0,27.207,9.861,32.66,24.869c5.501,15.127,2.444,32.464-7.645,44.04l-70.72,80.749
                              c-6.904,7.851-15.949,11.774-25.018,11.774C667.731,701.809,658.688,697.884,651.809,690.032 M227.513,552.212l70.695-80.745
                              c13.833-15.794,36.175-15.794,50.032,0l70.67,80.745c10.141,11.52,13.197,28.886,7.695,43.98
                              c-5.451,15.068-18.394,24.899-32.66,24.899h-30.366c15.82,69.492,70.72,121.12,136.421,121.12c19.667,0,38.696-4.508,56.53-13.406
                              c17.884-9.048,38.723,0.434,46.543,20.824c7.847,20.419-0.381,44.271-18.239,53.144C558.007,816.185,529.449,822.96,500,822.96
                              c-104.883,0-192.01-87.525-208.925-201.868h-38.57c-14.267,0-27.208-9.83-32.66-24.899
                              C214.368,581.098,217.399,563.731,227.513,552.212"/>
                            </svg>)}
                            Import products
                          </Nav.Link>
                          ) : (
                            <Nav.Link eventKey="second" className={store?.vr_store_status === 2 ? 'selected' : ''}>
                              {store?.vr_store_status === 2 ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                              </svg>) : (<svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                                <path d="M817.222,390.516C804.357,203.57,666.992,55.95,500,55.95c-142.84,0-265.43,105.907-305.07,260.421
                                C82.125,361.077,5.035,483.159,5.035,621.092c0,178.073,126.87,322.959,282.83,322.959h459.63
                                c136.447,0,247.47-126.821,247.47-282.583C994.965,534.323,921.799,425.243,817.222,390.516 M651.809,690.032l-70.721-80.748
                                c-10.112-11.578-13.144-28.914-7.692-44.04c5.451-15.009,18.394-24.871,32.659-24.871h30.366
                                C620.603,470.912,565.752,419.251,500,419.251c-19.717,0-38.799,4.51-56.632,13.468c-17.985,8.873-38.749-0.405-46.568-20.797
                                c-7.847-20.448,0.305-44.24,18.188-53.171c26.852-13.408,55.485-20.217,85.012-20.217c104.883,0,192.011,87.469,208.951,201.839
                                h38.544c14.291,0,27.207,9.861,32.66,24.869c5.501,15.127,2.444,32.464-7.645,44.04l-70.72,80.749
                                c-6.904,7.851-15.949,11.774-25.018,11.774C667.731,701.809,658.688,697.884,651.809,690.032 M227.513,552.212l70.695-80.745
                                c13.833-15.794,36.175-15.794,50.032,0l70.67,80.745c10.141,11.52,13.197,28.886,7.695,43.98
                                c-5.451,15.068-18.394,24.899-32.66,24.899h-30.366c15.82,69.492,70.72,121.12,136.421,121.12c19.667,0,38.696-4.508,56.53-13.406
                                c17.884-9.048,38.723,0.434,46.543,20.824c7.847,20.419-0.381,44.271-18.239,53.144C558.007,816.185,529.449,822.96,500,822.96
                                c-104.883,0-192.01-87.525-208.925-201.868h-38.57c-14.267,0-27.208-9.83-32.66-24.899
                                C214.368,581.098,217.399,563.731,227.513,552.212"/>
                              </svg>)}
                              Import products
                            </Nav.Link>
                          )}
                      </Nav.Item>
                      <Nav.Item>
                        {(store?.is_default || !store.is_accept_term) ? (
                          <Nav.Link>
                            {store?.vr_store_status === 2 ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                            </svg>) : (<svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                              <path d="M500.5,8.269C228.817,8.269,7.758,228.851,7.758,500.014c0,271.135,221.059,491.717,492.742,491.717
                              c271.684,0,492.742-220.582,492.742-491.717C993.242,228.851,772.184,8.269,500.5,8.269 M750.698,474.735L389.354,720.609
                              c-2.784,1.897-5.995,2.832-9.233,2.832c-2.642,0-5.257-0.595-7.672-1.869c-5.367-2.836-8.779-8.422-8.779-14.49V215.311
                              c0-6.066,3.41-11.624,8.779-14.487c5.399-2.835,11.905-2.467,16.905,0.992l361.375,245.815c4.433,3.089,7.159,8.14,7.159,13.581
                              C757.888,466.628,755.159,471.701,750.698,474.735"/>
                            </svg>)}
                            Publish VR-store
                          </Nav.Link>
                        ) : (
                          <Nav.Link eventKey="three" className={store?.vr_store_status === 2 ? 'selected' : ''}>
                            {store?.vr_store_status === 2 ? (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M0 10a10 10 0 1020 0 10 10 0 00-20 0zm15.2-1.8a1 1 0 00-1.4-1.4L9 11.6 6.7 9.3a1 1 0 00-1.4 1.4l3 3c.4.4 1 .4 1.4 0l5.5-5.5z"></path>
                            </svg>) : (<svg version="1.1" x="0px" y="0px" viewBox="0 0 1000 1000">
                              <path d="M500.5,8.269C228.817,8.269,7.758,228.851,7.758,500.014c0,271.135,221.059,491.717,492.742,491.717
                              c271.684,0,492.742-220.582,492.742-491.717C993.242,228.851,772.184,8.269,500.5,8.269 M750.698,474.735L389.354,720.609
                              c-2.784,1.897-5.995,2.832-9.233,2.832c-2.642,0-5.257-0.595-7.672-1.869c-5.367-2.836-8.779-8.422-8.779-14.49V215.311
                              c0-6.066,3.41-11.624,8.779-14.487c5.399-2.835,11.905-2.467,16.905,0.992l361.375,245.815c4.433,3.089,7.159,8.14,7.159,13.581
                              C757.888,466.628,755.159,471.701,750.698,474.735"/>
                            </svg>)}
                            Publish VR-store
                          </Nav.Link>
                        )}
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="get-ready-box ps-0">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        {store.is_default ? <ConnectStoreTwo store={store} /> : <ConnectStoreOne store={store} />}
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        {showImportProduct && store.vr_store_status === 1 && <ImportProductOne handleImportProduct={handleImportProduct} />}
                        {showImportProduct && store.vr_store_status === 2 && <ImportProductTwo />}
                        {!showImportProduct && <StoreProductList />}
                      </Tab.Pane>
                      <Tab.Pane eventKey="three">
                        <PublishVRStore store={store} getStoreDetail={getStoreDetail}/>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Dashboard;
