import { useEffect, useState } from 'react';
import {
  Button, Offcanvas, Form, InputGroup, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import useAuth from '../../../hooks/useAuth';
import { getStoreSession } from '../../../services/StoreService';
import { marketList, solutionCreate, themeStyleList } from '../../../services/SolutionService';
import ThemeModal from './ThemeModal';

const schema = yup
  .object({
    name: yup.string().label('Solution name').required(),
    market_id: yup.string().label('Market').required(),
    status: yup.string().label('Status').required(),
    theme_name: yup.string().label('Theme').required(),
    theme_style_id: yup.string().label('Theme Style').required(),
  })
  .required();

const SolutionCreate = ({ showSolutionCreate, handleShowSolutionCreate, getSolutions }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [markets, setMarkets] = useState([]);
  const [showThemeModal, setShowThemeModal] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState('');
  const [themeStyles, setThemeStyles] = useState([]);
  const { getSession } = useAuth();
  const user = getSession();
  const store = getStoreSession();

  const getMarkets = async () => {
    try {
      const response = await marketList(store.id);
      if (response.success) {
        setMarkets(response.data);
      } else {
        setMarkets([]);
      }
    } catch (error) {
      setMarkets([]);
    }
  };

  const getThemeStyles = async () => {
    try {
      const response = await themeStyleList(selectedTheme.id);
      if (response.success) {
        setThemeStyles(response.data);
      } else {
        setThemeStyles([]);
      }
    } catch (error) {
      setThemeStyles([]);
    }
  };

  const handleShowThemeModal = () => {
    setShowThemeModal(!showThemeModal);
  };

  const handleSelectedTheme = (theme) => {
    setSelectedTheme(theme);
    setValue('theme_name', theme.name);
    handleShowThemeModal();
  };

  const handleSolutionCreate = async (data) => {
    setIsLoading(true);
    const postData = {
      store_id: store.id,
      tenant_id: user.tenant_id,
      theme_style_id: data.theme_style_id,
      theme_id: selectedTheme.id,
      name: data.name,
      status: data.status,
      market_id: data.market_id,
    };
    try {
      const response = await solutionCreate(postData);
      if (response.success) {
        toast.success(response.message);
        await getSolutions();
        handleShowSolutionCreate();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setShow(showSolutionCreate);
  }, [showSolutionCreate]);

  useEffect(() => {
    (async () => {
      await getThemeStyles();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTheme]);

  useEffect(() => {
    (async () => {
      await getMarkets();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Offcanvas show={show} onHide={handleShowSolutionCreate} placement="end">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body className="px-5">
          <Offcanvas.Title className="pb-2">New Solution</Offcanvas.Title>
          <Form onSubmit={handleSubmit(handleSolutionCreate)} noValidate>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter the solution name"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label>Market</Form.Label>
              <Form.Select
                isInvalid={!!errors.market_id}
                {...register('market_id')}
              >
                {markets && markets.map((market) => <option key={market.id} value={market.id}>{market.name}</option>)}
              </Form.Select>
              <i className="bi bi-chevron-down"></i>
              <Form.Control.Feedback type="invalid">{errors.market_id?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Store Design Theme</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="text"
                  isInvalid={!!errors.theme_name}
                  {...register('theme_name')}
                />
                <div className="browseBtn position-relative">
                  <Button variant="secondary" onClick={handleShowThemeModal}>Browse</Button>
                </div>
                <Form.Control.Feedback type="invalid">{errors.theme_name?.message}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label>Theme Style</Form.Label>
              <Form.Select
                isInvalid={!!errors.theme_style_id}
                {...register('theme_style_id')}
              >
                {themeStyles && themeStyles.length > 0 && themeStyles.map((themeStyle) => (
                  <option key={themeStyle.id} value={themeStyle.id}>{themeStyle.name}</option>
                ))}
                {themeStyles && themeStyles.length === 0 && <option value="">Select Style</option>}
              </Form.Select>
              <i className="bi bi-chevron-down"></i>
              <Form.Control.Feedback type="invalid">{errors.theme_style_id?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label>Status</Form.Label>
              <Form.Select
                isInvalid={!!errors.status}
                {...register('status')}
              >
                <option value="published">Published</option>
                <option value="inactive">InActive</option>
              </Form.Select>
              <i className="bi bi-chevron-down"></i>
              <Form.Control.Feedback type="invalid">{errors.theme_style_id?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit" disabled={isLoading}>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">Create</span>
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <ThemeModal showThemeModal={showThemeModal} handleShowThemeModal={handleShowThemeModal} handleSelectedTheme={handleSelectedTheme}/>
    </>
  );
};

export default SolutionCreate;
