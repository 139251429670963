import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Image } from 'react-bootstrap';

import './login.css';
import logo from '../../assets/images/logo.png';
import useAuth from '../../hooks/useAuth';

const VerifyEmail = () => {
  const title = 'VCOM - Verify Eamil';
  const { verifyEmail } = useAuth();
  const token = useParams();
  const navigate = useNavigate();

  const handleVerifyEmail = async () => {
    try {
      const response = await verifyEmail(token);
      if (response.success) {
        navigate('/login', { state: { successMessage: response.message } });
      } else {
        navigate('/login', { state: { errorMessage: response.message } });
      }
    } catch (err) {
      navigate('/login', { state: { errorMessage: err.message } });
    }
  };
  useEffect(() => {
    (async () => {
      if (token) {
        await handleVerifyEmail();
      } else {
        navigate('/login', { state: { errorMessage: 'Invalid token.' } });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 bgLight px-3">
        <div className="loginCard mt-4">
            <div className="text-center">
              <Link className="logo" to={'/'}>
                <Image src={logo} alt="logo" />
              </Link>
              <h3>Verifying your VCOM Account</h3>
            </div>
        </div>
      </main>
    </>
  );
};

export default VerifyEmail;
