import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { toast, ToastContainer } from 'react-toastify';

import './store.css';

import BasicInformation from '../../../components/merchant/store/BasicInformation';
import EditBasicInformation from '../../../components/merchant/store/EditBasicInformation';
import MarketSetting from '../../../components/merchant/store/MarketSetting';
import EditMarketSetting from '../../../components/merchant/store/EditMarketSetting';
import ContactInformation from '../../../components/merchant/store/ContactInformation';
import EditContactInformation from '../../../components/merchant/store/EditContactInformation';
import StoreCurrency from '../../../components/merchant/store/StoreCurrency';
import StandardFormats from '../../../components/merchant/store/StandardFormats';
import { getStoreSession, storeDetail } from '../../../services/StoreService';

const StoreDetail = () => {
  const title = 'Store Detail';
  const [showBasicInfo, setShowBasicInfo] = useState(true);
  const [showMarketSetting, setShowMarketSetting] = useState(true);
  const [showContactInfo, setShowContactInfo] = useState(true);
  const [store, setStore] = useState({});

  const getStoreDetail = async () => {
    const storeData = getStoreSession();
    try {
      const response = await storeDetail(storeData.id);
      if (response.success) {
        // eslint-disable-next-line
        // console.log(response.data);
        setStore(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleBasicInfo = () => {
    setShowBasicInfo(!showBasicInfo);
  };

  const handleMarketSetting = () => {
    setShowMarketSetting(!showMarketSetting);
  };

  const handleContactInfo = () => {
    setShowContactInfo(!showContactInfo);
  };

  useEffect(() => {
    (async () => {
      await getStoreDetail();
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="store-page minHeight">
        <div className="px-3 mx_0 m_0 mx_3 mx-5 pt-5">
          <h2 className="title mb-3">{title}</h2>
            {showBasicInfo && <BasicInformation store={store} handleBasicInfo={handleBasicInfo} />}
            {!showBasicInfo && <EditBasicInformation
              store={store}
              handleBasicInfo={handleBasicInfo}
              getStoreDetail={getStoreDetail}
            />}
            {showMarketSetting && <MarketSetting store={store} handleMarketSetting={handleMarketSetting} />}
            {!showMarketSetting && <EditMarketSetting
              store={store}
              handleMarketSetting={handleMarketSetting}
              getStoreDetail={getStoreDetail}
            />}
            {showContactInfo && <ContactInformation store={store} handleContactInfo={handleContactInfo} />}
            {!showContactInfo && <EditContactInformation
              store={store}
              handleContactInfo={handleContactInfo}
              getStoreDetail={getStoreDetail}
            />}
           <StoreCurrency store={store} />
           <StandardFormats store={store} />
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default StoreDetail;
