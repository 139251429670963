import { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

const URLImage = ({
  imgProps, isSelected, onSelect, onChange,
}) => {
  const imgRef = useRef();
  const trRef = useRef();
  const [img] = useImage(imgProps.src);
  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([imgRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={img}
        width={imgProps.width}
        height={imgProps.height}
        x={imgProps.x}
        y={imgProps.y}
        offsetX={img ? imgProps.width / 2 : 0}
        offsetY={img ? imgProps.height / 2 : 0}
        onClick={onSelect}
        onTap={onSelect}
        ref={imgRef}
        {...imgProps}
        draggable
        onDragEnd={(e) => {
          onChange({
            ...imgProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={(e) => {
          // const node = imgProps.current;
          const node = e.currentTarget;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...imgProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (Math.abs(newBox.width) < 5 || Math.abs(newBox.height) < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default URLImage;
