import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Card, Row, Col, Form, Image, Tab, Table, Button, Tabs,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import Moment from 'react-moment';

import './billing.css';
import visaIcon from '../../../assets/images/cards/visa-svgrepo-com.svg';
import masterIcon from '../../../assets/images/cards/mastercard-svgrepo-com.svg';
import discoverIcon from '../../../assets/images/cards/discover-svgrepo-com.svg';
import amexIcon from '../../../assets/images/cards/amex-3-svgrepo-com.svg';
import dinersIcon from '../../../assets/images/cards/diners-3-svgrepo-com.svg';
import jcbIcon from '../../../assets/images/cards/jcb-svgrepo-com.svg';
import unionpayIcon from '../../../assets/images/cards/unionpay-2-svgrepo-com.svg';
import { billingHistory, cardList } from '../../../services/BillingService';
import useAuth from '../../../hooks/useAuth';
import { getStoreSession, storeUpdate } from '../../../services/StoreService';
import AddPaymentMethod from '../../../components/merchant/billing/AddPaymentMethod';
import EditPaymentMethod from '../../../components/merchant/billing/EditPaymentMethod';
import RemovePaymentMethod from '../../../components/merchant/billing/RemovePaymentMethod';
import AddVatNumber from '../../../components/merchant/billing/AddVatNumber';

const Billing = () => {
  const title = 'Billing';
  const [activeTab, setActiveTab] = useState('all');
  const [cards, setCards] = useState([]);
  const [charges, setCharges] = useState([]);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const [showEditPaymentMethod, setShowEditPaymentMethod] = useState(false);
  const [showRemovePaymentMethod, setShowRemovePaymentMethod] = useState(false);
  const [showAddVatNumber, setShowAddVatNumber] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const { getSession } = useAuth();
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  const handleShowAddPaymentMethod = () => {
    setShowAddPaymentMethod(!showAddPaymentMethod);
  };

  const handleShowEditPaymentMethod = () => {
    setShowEditPaymentMethod(!showEditPaymentMethod);
  };

  const handleEditPaymentMethod = (card) => {
    setSelectedCard(card);
    handleShowEditPaymentMethod();
  };

  const handleShowRemovePaymentMethod = () => {
    setShowRemovePaymentMethod(!showRemovePaymentMethod);
  };

  const handleDeletePaymentMethod = (card) => {
    setSelectedCard(card);
    handleShowRemovePaymentMethod();
  };

  const handleShowAddVatNumber = () => {
    setShowAddVatNumber(!showAddVatNumber);
  };

  const handleRemoveVatNuber = async () => {
    try {
      const store = getStoreSession();
      const response = await storeUpdate({ store_id: store.id, vat_no: null, is_vat_no: false });
      if (response.success) {
        toast.success('VAT number status is updated successfully.');
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getCharges = async () => {
    const store = getStoreSession();
    try {
      const response = await billingHistory(store.id);
      if (response.success) {
        setCharges(response.data);
      }
    } catch (error) {
      setCharges([]);
    }
  };

  const getCards = async () => {
    const user = getSession();
    try {
      const response = await cardList(user.tenant_id);
      if (response.success) {
        setCards(response.data);
      }
    } catch (error) {
      setCards([]);
    }
  };

  const showCharges = () => {
    const chargeRows = charges.map((charge) => {
      const subscriptions = [];
      if (charge.Charge !== null && charge.SubscriptionInvoice?.length > 0) {
        subscriptions.push(getSubscriptions(charge));
      }
      return subscriptions;
    });
    return chargeRows;
  };

  const getSubscriptions = (charge) => {
    let subscriptions = [];
    if (activeTab === 'processing') {
      subscriptions = charge.SubscriptionInvoice.map((eachInvoice) => ((eachInvoice.invoice_status === 'draft' || eachInvoice.invoice_status === 'open') && <tr key={eachInvoice.id}>
        <td><Moment format="DD/MM/YYYY">{eachInvoice.period_start}</Moment></td>
        <td>{eachInvoice.invoice_number}</td>
        <td>{charge.Plan?.name}</td>
        <td>{eachInvoice.currency.toUpperCase()} {eachInvoice.amount_total / 100}</td>
        <td>{eachInvoice.invoice_status === 'draft' || eachInvoice.invoice_status === 'open' ? 'Processing' : capitalizeFirstLetter(eachInvoice.invoice_status)}</td>
      </tr>));
    } else if (activeTab === 'paid') {
      subscriptions = charge.SubscriptionInvoice.map((eachInvoice) => (eachInvoice.invoice_status === 'paid' && <tr key={eachInvoice.id}>
        <td><Moment format="DD/MM/YYYY">{eachInvoice.period_start}</Moment></td>
        <td>{eachInvoice.invoice_number}</td>
        <td>{charge.Plan?.name}</td>
        <td>{eachInvoice.currency.toUpperCase()} {eachInvoice.amount_total / 100}</td>
        <td>{eachInvoice.invoice_status === 'draft' || eachInvoice.invoice_status === 'open' ? 'Processing' : capitalizeFirstLetter(eachInvoice.invoice_status)}</td>
      </tr>));
    } else {
      subscriptions = charge.SubscriptionInvoice.map((eachInvoice) => (<tr key={eachInvoice.id}>
        <td><Moment format="DD/MM/YYYY">{eachInvoice.period_start}</Moment></td>
        <td>{eachInvoice.invoice_number}</td>
        <td>{charge.Plan?.name}</td>
        <td>{eachInvoice.currency.toUpperCase()} {eachInvoice.amount_total / 100}</td>
        <td>{eachInvoice.invoice_status === 'draft' || eachInvoice.invoice_status === 'open' ? 'Processing' : capitalizeFirstLetter(eachInvoice.invoice_status)}</td>
      </tr>));
    }
    return subscriptions;
  };

  useEffect(() => {
    (async () => {
      await getCards();
      await getCharges();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="planCreate billing minHeight">
        <div className="px-3 m_0 mx_3 mx_0 mx-5 pt-5">
          <h1 className="h5 pb-3 mb-3 border-bottom">{title}</h1>
          <h6 className="mb-1 text15">Payment methods</h6>
          <p className="text-muted mb-4">Manage how you pay your bills at VCOM.
            <Link to={'https://vcomverse.io/docs/billing/'} className="text-decoration-none blueColor" target='_blank'>Learn more about billing.<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
          </p>
          <Card className="subscriptionPlan mb-4">
            <Card.Header className="p-4 bg-transparent border-bottom">
              <Card.Title className="h6 mb-0">Default payment methods</Card.Title>
            </Card.Header>
            <Card.Body className="p-0">
              <Row className="m-0">
                {cards && cards.map((card) => (<Col key={card.id} md={12} className="px-4 pt-3 pb-2 border-bottom">
                    <div className="d-flex align-items-top">
                      <Link onClick={() => handleEditPaymentMethod(card)} className="text-decoration-none text-dark">
                        <div className="editPayment d-flex align-items-center">
                          {card.brand === 'visa' && <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />}
                          {card.brand === 'mastercard' && <Image className="paymentIcon" src={masterIcon} alt="masterIcon" />}
                          {card.brand === 'discover' && <Image className="paymentIcon" src={discoverIcon} alt="discoverIcon" />}
                          {card.brand === 'amex' && <Image className="paymentIcon" src={amexIcon} alt="amexIcon" />}
                          {(card.brand === 'dinersclub' || card.brand === 'diners') && <Image className="paymentIcon" src={dinersIcon} alt="dinersIcon" />}
                          {card.brand === 'jcb' && <Image className="paymentIcon" src={jcbIcon} alt="jcbIcon" />}
                          {card.brand === 'unionpay' && <Image className="paymentIcon" src={unionpayIcon} alt="unionpayIcon" />}
                          <p className="mb-0 ms-3">{card.name}<br />Ending in ************{card.card_no}</p>
                        </div>
                      </Link>
                      <Button className="delPayment" onClick={() => handleDeletePaymentMethod(card)} variant="link"><i className="bi bi-x-circle-fill"></i></Button>
                    </div>
                  </Col>
                ))}
              </Row>
            </Card.Body>
            <Card.Footer className="p-4 bg-transparent text-end border-0">
              <Button className="edit blueColor p-0" onClick={handleShowAddPaymentMethod} variant="link">Add payment method</Button>
            </Card.Footer>
          </Card>
          <Card>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12}>
                  <Tabs
                    activeKey={activeTab}
                    onSelect={(k) => setActiveTab(k)}
                    className="tabs"
                  >
                    <Tab eventKey="all" title="All">
                      <div className="p-3 border-bottom">
                        <div className="d-flex py-1 px-2 align-items-center rounded-1 border sort">
                          <span>Sort by</span>
                          <Form.Select aria-label="Default select example">
                            <option>Newest</option>
                            <option value="1">Newest update</option>
                            <option value="2">Oldest update</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Invoice Date</th>
                              <th>Invoice Number</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showCharges()}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="paid" title="Paid">
                      <div className="p-3 border-bottom">
                        <div className="d-flex py-1 px-2 align-items-center rounded-1 border sort">
                          <span>Sort by</span>
                          <Form.Select aria-label="Default select example">
                            <option>Newest</option>
                            <option value="1">Newest update</option>
                            <option value="2">Oldest update</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Invoice Date</th>
                              <th>Invoice Number</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showCharges()}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="failed" title="Failed">
                      <div className="p-3 border-bottom">
                        <div className="d-flex py-1 px-2 align-items-center rounded-1 border sort">
                          <span>Sort by</span>
                          <Form.Select aria-label="Default select example">
                            <option>Newest</option>
                            <option value="1">Newest update</option>
                            <option value="2">Oldest update</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Invoice Date</th>
                              <th>Invoice Number</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="processing" title="Processing">
                      <div className="p-3 border-bottom">
                        <div className="d-flex py-1 px-2 align-items-center rounded-1 border sort">
                          <span>Sort by</span>
                          <Form.Select aria-label="Default select example">
                            <option>Newest</option>
                            <option value="1">Newest update</option>
                            <option value="2">Oldest update</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Invoice Date</th>
                              <th>Invoice Number</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                          <tbody>
                            {showCharges()}
                          </tbody>
                        </Table>
                      </div>
                    </Tab>
                    <Tab eventKey="refunded" title="Refunded">
                      <div className="p-3 border-bottom">
                        <div className="d-flex py-1 px-2 align-items-center rounded-1 border sort">
                          <span>Sort by</span>
                          <Form.Select aria-label="Default select example">
                            <option>Newest</option>
                            <option value="1">Newest update</option>
                            <option value="2">Oldest update</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <Table hover>
                          <thead>
                            <tr>
                              <th>Invoice Date</th>
                              <th>Invoice Number</th>
                              <th>Plan</th>
                              <th>Amount</th>
                              <th>Payment</th>
                            </tr>
                          </thead>
                        </Table>
                      </div>
                    </Tab>
                  </Tabs>
                </Col>
              </Row>
            </Tab.Container>
          </Card>
          <div className="my-4">
            <h3 className="fs-6">Tax registration</h3>
            <p className="text-muted">Manage your tax registration information. Check with a tax expert to understand your tax obligations.</p>
          </div>
          <Card className="p-4 vatNumber">
            <h3 className="fs-6">VAT Number</h3>
            <p className="mb-0 text-muted">
              If your sales exceed the VAT threshold for your country, the EU requires
              you to register for a VAT number and share it with VCOM. We'll verify it with the VAT Information
              Exchange System (VIES) to make sure that you're charged the required taxes for VCOM'S services.<br />
              <Link to={'https://vcomverse.io/docs/taxing/'} className="text-decoration-none blueColor" target='_blank' rel="noopener noreferrer">Learn more about VAT requirements. <i className="bi bi-box-arrow-up-right ms-2"></i></Link>
            </p>
            <div className="mt-3">
              <Button variant="outline-secondary" className="cancel" onClick={handleShowAddVatNumber}>Add VAT number</Button>
              <Button variant="link" className="text-decoration-none blueColor" onClick={handleRemoveVatNuber}>I don't have a VAT number</Button>
            </div>
          </Card>
        </div>
      </div>
      <ToastContainer />
      <AddPaymentMethod
        getCards={getCards}
        showAddPaymentMethod={showAddPaymentMethod}
        handleShowAddPaymentMethod={handleShowAddPaymentMethod}
      />
      <EditPaymentMethod
        selectedCard={selectedCard}
        getCards={getCards}
        showEditPaymentMethod={showEditPaymentMethod}
        handleShowEditPaymentMethod={handleShowEditPaymentMethod}
      />
      <RemovePaymentMethod
        selectedCard={selectedCard}
        getCards={getCards}
        showRemovePaymentMethod={showRemovePaymentMethod}
        handleShowRemovePaymentMethod={handleShowRemovePaymentMethod}
      />
      <AddVatNumber
        showAddVatNumber={showAddVatNumber}
        handleShowAddVatNumber={handleShowAddVatNumber}
      />
    </>
  );
};

export default Billing;
