import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';

import shopifyLogo from '../../../assets/images/shopify-logo.png';

const ConnectStoreTwo = ({ store }) => (
  <>
    <div className="d-flex align-items-start">
      <div className="storeProducts w-100">
        <h3 className="text17 mb-2">Connect to your online store</h3>
        <p className="mb-3 text-muted">
          Before you start your VR-store, you need to connect this VCOM-account to your current online store provider.
          To complete this step, navigate to your online store provider’s marketplace to download and install the VCOM-Connector.
        </p>
        <h6>Supported online store providers:</h6>
        <Link to={'https://www.shopify.com'} target="_blank" className="shopifyLogo">
          <Image src={shopifyLogo} />
        </Link>
        <div className="mt-4">
          <Link className="infoColor text-decoration-none" to={'https://vcomverse.io/docs/connect-to-online-store/'} target="_blank" rel="noopener noreferrer"><i className="bi bi-question-circle-fill me-1"></i>Learn more about how to connect to online store<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
        </div>
      </div>
      <div className="col-md-3"></div>
    </div>
  </>
);

export default ConnectStoreTwo;
