import { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { solutionUpdate } from '../../../services/SolutionService';

const SolutionPublishModal = ({
  showSolutionPublish,
  handleShowSolutionPublish,
  selectedSolution,
  getSolutions,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [solution, setSolution] = useState({});

  const handleSolutionPublish = async () => {
    try {
      const solutionJson = {
        id: solution.id,
        store_id: solution.store_id,
        tenant_id: solution.tenant_id,
        theme_style_id: solution.theme_style_id,
        theme_id: solution.theme_id,
        name: solution.name,
        status: 'published',
        market_id: solution.market_id,
        webbrowser: solution.webbrowser,
        vrheadset: solution.vrheadset,
        showloaderlogo: solution.showloaderlogo,
        customlogo: solution.customlogo,
        showuserhelp: solution.showuserhelp,
        backgroundcolor: solution.backgroundcolor,
        textcolor: solution.textcolor,
        store_url: solution.store_url,
        requires_password: solution.requires_password,
        password: solution.password,
      };
      setIsLoading(true);
      const response = await solutionUpdate(solutionJson);
      if (response.success) {
        toast.success('Solution has been published.');
        setIsLoading(false);
        await getSolutions();
        handleShowSolutionPublish();
      } else {
        setIsLoading(false);
        handleShowSolutionPublish();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowSolutionPublish();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setShow(showSolutionPublish);
  }, [showSolutionPublish]);

  useEffect(() => {
    setSolution(selectedSolution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSolution]);

  return (
    <>
      <Modal show={show} onHide={handleShowSolutionPublish}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Publish Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Publish?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowSolutionPublish}>
            No
          </Button>
          <Button variant="primary" onClick={handleSolutionPublish} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SolutionPublishModal;
