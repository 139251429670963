import { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import File from './File';
import folderIcon from '../../../assets/images/virtual/folderIcon.png';

export default function Folder({ item, handleShow, contentSelected }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const handleIsExpanded = (clickedItem) => {
    handleShow(1, clickedItem);
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (contentSelected) {
      setSelectedItem(contentSelected.id);
    }
  }, [contentSelected]);

  return (
    <>
      <p
        key={`folder_${item.id}`}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleIsExpanded(item)}
        className={selectedItem === item.id ? 'selected' : ''}
      >
        <Image src={folderIcon} width={17} height={14} alt='folder' /> {item.name}
      </p>
      {isExpanded && item.children && item.children.length > 0 && (<p
        className="expandedOver"
        key={item.id}
      >
        {item.children.map((eachItem) => {
          let returnItem = '';
          if (eachItem.isFolder) {
            returnItem = <Folder key={`folder_${eachItem.id}`} item={eachItem} handleShow={handleShow} contentSelected={contentSelected} />;
          } else {
            returnItem = <File key={eachItem.id} item={eachItem} />;
          }
          return returnItem;
        })}
      </p>)}
    </>
  );
}
