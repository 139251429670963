import { useState } from 'react';
import { Image } from 'react-bootstrap';
import File from './File';
import {
  storefront, avatars, products, entrancefloor, tablet, interior, apps,
} from '../../../../assets/images/virtual';

export default function Folder({ item, selectedItem, handleSelectedItem }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIsExpanded = (data) => {
    handleSelectedItem(data);
    setIsExpanded(!isExpanded);
  };

  const getFolderImage = (folderName) => {
    let folderImg = <Image src={storefront} width={16} height={16} alt='folder' />;
    if (folderName === 'Store Front') {
      folderImg = <Image src={storefront} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Entrance floor') {
      folderImg = <Image src={entrancefloor} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Avtars') {
      folderImg = <Image src={avatars} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Tablet') {
      folderImg = <Image src={tablet} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Interior') {
      folderImg = <Image src={interior} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Products') {
      folderImg = <Image src={products} width={16} height={16} alt='folder' />;
    } else if (folderName === 'Apps') {
      folderImg = <Image src={apps} width={16} height={16} alt='folder' />;
    }
    return folderImg;
  };

  return (
    <>
      <p
        key={`folder_${item.id}`}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleIsExpanded(item)}
        className={selectedItem.id === item.id ? 'selected' : ''}
      >
        {isExpanded ? <i className="bi bi-chevron-down"></i> : <i className="bi bi-chevron-right"></i>} {getFolderImage(item.name)} {item.name}
      </p>
      {isExpanded && item.items && item.items.length > 0 && (<p
        key={item.id}
        style={{
          cursor: 'pointer',
        }}
        className="objectTree"
      >
        {item.items.map((eachItem) => {
          let returnItem = '';
          if (eachItem.isFolder) {
            returnItem = <Folder key={`folder_${eachItem.id}`} item={eachItem} selectedItem={selectedItem} handleSelectedItem={handleSelectedItem} />;
          } else {
            returnItem = <File key={eachItem.id} item={eachItem} selectedItem={selectedItem} handleSelectedItem={handleSelectedItem} />;
          }
          return returnItem;
        })}
      </p>)}
    </>
  );
}
