import { useState } from 'react';
import { Image } from 'react-bootstrap';
import Folder from '../Explorer/Folder';
import File from '../Explorer/File';
import productIcon from '../../../assets/images/virtual/productIcon.png';
import appIcon from '../../../assets/images/virtual/appIcon.png';

const MyAssetLibrary = ({
  optionSelected, handleShow, contentSelected, folderStructureData,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [assets, setAssets] = useState(folderStructureData);
  const handleShowClick = (option, data = {}) => {
    handleShow(option, data);
  };

  return (
    <>
      <div className="fileExplorer">
        <h6 key="my_assets" className="text-white mb-0 assetsHeading">My Assets Library</h6>
        <div className="foldertree pt-3">
          {assets.map((item) => (item.isFolder ? <Folder key={`asset_${item.id}`} item={item} handleShow={handleShow} contentSelected={contentSelected} /> : <File key={item.id} item={item} />))}
          <p
            key="products"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(2)}
            className={optionSelected === 2 ? 'selected' : ''}
          >
            <Image src={productIcon} width={17} height={17} alt='product' /> Products
          </p>
          <p
            key="apps"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(3)}
            className={optionSelected === 3 ? 'selected' : ''}
          >
            <Image src={appIcon} width={17} height={18} alt='app' /> Apps
          </p>
        </div>
      </div>
    </>
  );
};

export default MyAssetLibrary;
