import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import './profile.css';
import ProfileView from '../../components/merchant/ProfileView';
import ProfileEdit from '../../components/merchant/ProfileEdit';
import useAuth from '../../hooks/useAuth';
import { tenantDetail } from '../../services/UserService';

const Profile = () => {
  const title = 'VCOM';
  const [isEdit, setIsEdit] = useState(false);
  const [tenant, setTenant] = useState({});
  const { getSession } = useAuth();
  const user = getSession();

  const handleIsEdit = () => {
    setIsEdit(!isEdit);
  };

  const getTenant = async () => {
    try {
      const response = await tenantDetail(user.id);
      if (response.success) {
        setTenant(response.data);
      } else {
        setTenant({});
      }
    } catch (error) {
      setTenant({});
    }
  };

  useEffect(() => {
    (async () => {
      await getTenant();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!isEdit && <ProfileView tenant={tenant} handleIsEdit={handleIsEdit} />}
      {isEdit && <ProfileEdit tenant={tenant} handleIsEdit={handleIsEdit} getTenant={getTenant} />}
      <ToastContainer />
    </>
  );
};

export default Profile;
