import {
  BrowserRouter, Navigate, Routes, Route,
} from 'react-router-dom';

// Layouts
import AuthLayout from '../layouts/AuthLayout';
import MerchantLayout from '../layouts/MerchantLayout';
import VirtualLayout from '../layouts/VirtualLayout';
import AdminLayout from '../layouts/AdminLayout';
import OtherLayout from '../layouts/OtherLayout';
// Other
import Home from '../pages/Home';
import Test from '../pages/Test';
// Auth
import Login from '../pages/Auth/Login';
import Logout from '../pages/Auth/Logout';
import Signup from '../pages/Auth/Signup';
import ResendVerifyEmail from '../pages/Auth/ResendVerifyEmail';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import StoreList from '../pages/Auth/StoreList';
import VerifyEmail from '../pages/Auth/VerifyEmail';
import NotFound from '../pages/NotFound';
// Merchant
import Profile from '../pages/Merchant/Profile';
import Dashboard from '../pages/Merchant/Dashboard';
import ProductList from '../pages/Merchant/product/ProductList';
import ProductDetail from '../pages/Merchant/product/ProductDetail';
import ProductSync from '../pages/Merchant/product/ProductSync';
import CategoryList from '../pages/Merchant/category/CategoryList';
import StoreDetail from '../pages/Merchant/store/StoreDetail';
import SubscriptionDetail from '../pages/Merchant/subscription/SubscriptionDetail';
import SubscriptionPlanList from '../pages/Merchant/subscription/SubscriptionPlanList';
import SubscriptionPlanCreate from '../pages/Merchant/subscription/SubscriptionPlanCreate';
import Billing from '../pages/Merchant/billing/Billing';
import SolutionList from '../pages/Merchant/virtual/SolutionList';
import SolutionEdit from '../pages/Merchant/virtual/SolutionEdit';

// Admin
import AdminProfile from '../pages/Admin/Profile';
import AdminDashboard from '../pages/Admin/Dashboard';
import AdminApps from '../pages/Admin/Apps';
import AdminUsers from '../pages/Admin/Users';
import AdminSettings from '../pages/Admin/Settings';

import AuthProvider from '../hooks/AuthProvider';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<AuthLayout />}>
            <Route path="" element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="signup" element={<Signup />} />
            <Route path="resend/verify-email" element={<ResendVerifyEmail />} />
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="store/list" element={<StoreList />} />
            <Route path="verify-email/:token" element={<VerifyEmail />} />
            <Route path="not-found" element={<NotFound />} />
            <Route path="*" element={<Navigate to="/not-found" />} />
          </Route>
          <Route path="other" element={<OtherLayout />}>
            <Route path="" element={<Home />} />
            <Route path="test" element={<Test />} />
          </Route>
          <Route path="merchant" element={<MerchantLayout />}>
            <Route path="" element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="product/list" element={<ProductList />} />
            <Route path="product/detail/:productId" element={<ProductDetail />} />
            <Route path="product/sync" element={<ProductSync />} />
            <Route path="product/categories" element={<CategoryList />} />
            <Route path="store/detail" element={<StoreDetail />} />
            <Route path="subscription/detail" element={<SubscriptionDetail />} />
            <Route path="subscription/plan/list" element={<SubscriptionPlanList />} />
            <Route path="subscription/plan/:planId" element={<SubscriptionPlanCreate />} />
            <Route path="billing" element={<Billing />} />
          </Route>
          <Route path="virtual" element={<VirtualLayout />}>
            <Route path="solution" element={<SolutionList />} />
            <Route path='solution/edit/:solutionId' element={<SolutionEdit />} />
          </Route>
          <Route path="admin" element={<AdminLayout />}>
            <Route path="" element={<AdminDashboard />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="apps" element={<AdminApps />} />
            <Route path="users" element={<AdminUsers />} />
            <Route path="settings" element={<AdminSettings />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
