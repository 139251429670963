import { useEffect, useState } from 'react';
import {
  Col, Row, Image,
} from 'react-bootstrap';

const ContentList = ({ dragUrl, contentSelected, folderStructureData }) => {
  const [folderCrumb, setFolderCrumb] = useState([]);
  const [showContentData, setShowContentData] = useState(false);
  const [contentImages, setContentImages] = useState([]);

  const importAll = (r) => {
    // const images = {};
    // r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    // return images;
    const images = [];
    r.keys().forEach((item, index) => { images.push(r(item)); });
    return images;
  };

  const getContentImages = () => {
    const images = importAll(require.context('../../../assets/images/virtual/content/themecollections/displaystands', false, /\.(png|jpe?g|svg)$/));
    setContentImages(images);
  };

  // const getBreadCrumbs = () => {
  //   let bredcrumbData = '';
  //   if (folderCrumb && folderCrumb.length > 0) {
  //     folderCrumb.forEach((eachCrumb, i) => {
  //       bredcrumbData += eachCrumb;
  //       if (i !== (folderCrumb.length - 1)) {
  //         bredcrumbData += ' / ';
  //       }
  //     });
  //   }
  //   return bredcrumbData;
  // };

  function searchObjectArray(arr, key, value) {
    let result = [];
    arr.forEach((obj) => {
      if (obj[key] === value) {
        result.push(obj);
      } else if (obj.children) {
        result = result.concat(searchObjectArray(obj.children, key, value));
      }
    });
    return result;
  }

  useEffect(() => {
    if (contentSelected && contentSelected?.name) {
      if (contentSelected.depth === 1) {
        setFolderCrumb([contentSelected]);
      } else if (contentSelected.depth === 2) {
        if (folderCrumb.length >= 2) {
          if (folderCrumb.length >= 3) {
            folderCrumb.splice(-1);
          }
          folderCrumb[1] = contentSelected;
          setFolderCrumb(folderCrumb);
        } else {
          folderCrumb.push(contentSelected);
          setFolderCrumb(folderCrumb);
        }
      } else if (contentSelected.depth === 3) {
        if (folderCrumb.length >= 3) {
          const newLinkParent = contentSelected.link.split(contentSelected.name.toLowerCase().replace(/\s/g, ''))[0];
          if (newLinkParent.includes(folderCrumb[1].link)) {
            folderCrumb[2] = contentSelected;
            setFolderCrumb(folderCrumb);
          } else {
            const parentDataArr = searchObjectArray(folderStructureData, 'link', newLinkParent.replace(/\/$/, ''));
            folderCrumb[1] = parentDataArr[0]; // eslint-disable-line
            folderCrumb[2] = contentSelected;
            setFolderCrumb(folderCrumb);
          }
        } else {
          folderCrumb.push(contentSelected);
          setFolderCrumb(folderCrumb);
        }
      } else {
        folderCrumb.push(contentSelected);
        setFolderCrumb(folderCrumb);
      }
      if (contentSelected.name === 'Display Stands' || contentSelected.name === 'Other stuff') {
        getContentImages();
        setShowContentData(true);
      } else {
        setContentImages([]);
        setShowContentData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSelected]);

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <div className="d-flex align-items-center mb_2 py-2 myProduct">
            <h6 className="text-white mb-0 me-2"> &nbsp; </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <div className="contentImage_holder">
            <div className='folderCrumb'>
              <ol
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                }}
              >
              {folderCrumb && folderCrumb.length > 0 && folderCrumb.map((item, index) => (
                <li key={`bread_${index}`}>
                  <span>
                    {item.name}
                  </span>
                  {index < folderCrumb.length - 1 && (
                    <span style={{ margin: '0 8px' }}>/</span>
                  )}
                </li>
              ))}
            </ol>
            </div>
            <div className="contentbox">
              {showContentData && contentImages && contentImages.map((contentImage, i) => (
                <div className='content_thumbnails' key={`img_${i}`}>
                  <Image
                    src={contentImage} // contentImage.default for linux
                    thumbnail
                    alt="image"
                    draggable={true}
                    onDragStart={(e) => {
                      // eslint-disable-next-line
                      dragUrl.current = e.target.src;
                    }}
                  />
                  <p>Display Stand.glb</p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContentList;
