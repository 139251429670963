import { Link } from 'react-router-dom';

const ConnectStoreOne = ({ store }) => (
  <>
    <div className="d-flex align-items-start">
      <div className="connectStore w-100">
        <div className="d-block">
          <h3 className="text17 mb-4">Connect VCOM account to online store</h3>
          <div className="statusInfo">
            <p className="mb-2"><b>You're connected</b></p>
            <p className="mb-1">
              <b>Store:</b> {store?.shopify_myshopify_domain ? `${store.store_name} (${store.shopify_myshopify_domain})` : store.store_name}
            </p>
            <p className="mb-1">
              <b>StoreID:</b> {store.shopify_store_id ? `#${store.shopify_store_id}` : '-'}
            </p>
          </div>
          <div className="mt-4">
            <Link className="infoColor text-decoration-none" to={'https://vcomverse.io/docs/connect-to-online-store/'} target="_blank" rel="noopener noreferrer"><i className="bi bi-question-circle-fill me-1"></i>Learn more about how to connect to online store<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
          </div>
        </div>
      </div>
      <div className="col-md-3"></div>
    </div>
  </>
);

export default ConnectStoreOne;
