import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';

import './subscriptionplanlist.css';
import { planList } from '../../../services/BillingService';

function SubscriptionPlanList() {
  const [plans, setPlans] = useState([]);

  const getPlans = async () => {
    const response = await planList();
    if (response.success) {
      setPlans(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      getPlans();
    })();
  }, [plans]);

  return (
    <>
      <Helmet>
        <title>VCOM</title>
      </Helmet>
      <div className="planList minHeight">
        <div className="px-3 mx_0 m_0 mx_3 mx-5 pt-5">
          <p className="fs-5 fw-500">Select a subscription plan for your VCOM account</p>
          <p className="text-muted">Choose a plan that suits you best. Subscription is automatically renewed along with payment
            withdraw from registered credit card. You can cancel the subscription at any time.</p>
          <Row className="pt-3">
            {plans && plans.map((plan) => plan.status && plan.name !== 'Trial' && (<Col key={plan.id} xs={12} md={4} className="mb-4">
                  <Card className="p-4 text-center">
                    <Card.Title className="mb-3">{plan.name}</Card.Title>
                    <Card.Text className="text-muted" dangerouslySetInnerHTML={ { __html: plan.description } }></Card.Text>
                    <Card.Body className="pb-2">
                      <span className="text-muted">{plan.currency}</span>
                      <span className="fs-3 px-1">{plan.price}</span>
                      <span className="text-muted">/ {plan.interval}</span>
                    </Card.Body>
                    <Card.Footer className="p-0 bg-transparent border-0">
                      <Link to={`/merchant/subscription/plan/${plan.id}`} className="btn btn-primary">Choose Plan</Link>
                    </Card.Footer>
                  </Card>
                </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlanList;
